<template>
  <v-form :disabled="!isEditBtn" ref="form" v-model="valid">
    <v-row class="mt-10" justify="center">
      <v-col cols="12" md="10">
        <p class="font-weight-medium title ma-0">
          {{ $vuetify.lang.t("$vuetify.main.patientDashboard.settings.title") }}
        </p>
        <div class="d-flex align-baseline">
          <v-col md="6">
            <v-text-field
              required
              v-model="patientName"
              :rules="patientNameRules"
              :label="
                $vuetify.lang.t(`$vuetify.main.patientDashboard.settings.name`)
              "
              value=""
              outlined
            ></v-text-field>
          </v-col>
        </div>
        <div class="d-flex align-baseline">
          <v-col md="6">
            <v-text-field
              required
              v-model="patientAge"
              :rules="patientAgeRules"
              :label="
                $vuetify.lang.t(`$vuetify.main.patientDashboard.settings.age`)
              "
              outlined
            ></v-text-field>
          </v-col>
        </div>

        <p class="font-weight-medium title ma-0">
          {{
            $vuetify.lang.t("$vuetify.main.patientDashboard.settings.contacts")
          }}
        </p>
        <div class="d-flex align-baseline">
          <v-col md="8">
            <vue-phone-number-input
              :disabled="!isEditBtn"
              v-model="userPhoneNumber"
              :loader="hasLoaderActive"
              :error="hasErrorActive"
              :default-country-code="defaultCountry"
              :preferred-countries="countriesList"
              show-code-on-list
              required
              @update="updatedData"
              :translations="translations"
            />
            <v-checkbox
              v-model="patientNotificationPerHourOnPhone"
              :label="
                $vuetify.lang.t(
                  `$vuetify.main.patientDashboard.settings.smsNotification`
                )
              "
            ></v-checkbox>
          </v-col>
        </div>
        <div class="d-flex align-baseline">
          <v-col md="8">
            <v-text-field
              v-model="patientEmail"
              :rules="patientEmailRules"
              label="Email"
              :placeholder="this.$store.getters['user/getUser'].email"
              required
              outlined
              :hint="
                $vuetify.lang.t(`$vuetify.main.patientDashboard.settings.hint`)
              "
              persistent-hint
            ></v-text-field>

            <v-checkbox
              class="pt-0 mt-0"
              v-model="patientNotificationBlog"
              :label="
                $vuetify.lang.t(
                  `$vuetify.main.patientDashboard.settings.blogLabel`
                )
              "
            ></v-checkbox>
            <v-row class="d-flex align-center pl-3">
              <v-checkbox
                class="pt-0 mt-0"
                v-model="patientNotificationOnEmailChecked"
                :label="
                  $vuetify.lang.t(
                    `$vuetify.main.patientDashboard.settings.notificationLabel`
                  )
                "
              ></v-checkbox>
              <v-col md="4">
                <v-select
                  :disabled="!patientNotificationOnEmailChecked"
                  :items="items"
                  item-value="id"
                  item-text="title"
                  v-model="patientNotificationOnEmailPerDynamicTime"
                  :label="
                    $vuetify.lang.t(
                      `$vuetify.main.patientDashboard.settings.timeLabel`
                    )
                  "
                  outlined
                ></v-select>
              </v-col>
              <p
                class="align-self-center text--secondary"
                :class="{ 'text--disabled': !isEditBtn }"
              >
                {{
                  $vuetify.lang.t(
                    `$vuetify.main.patientDashboard.settings.beforeSession`
                  )
                }}
              </p>
            </v-row>
          </v-col>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            @click="validate"
            v-if="isEditBtn"
            depressed
            color="success"
            class="mb-2"
          >
            {{
              $vuetify.lang.t(`$vuetify.main.patientDashboard.settings.save`)
            }}
          </v-btn>

          <v-btn
            @click="isEditBtn = !isEditBtn"
            v-if="!isEditBtn"
            depressed
            color="warning"
            class="mb-2"
          >
            {{
              $vuetify.lang.t(`$vuetify.main.patientDashboard.settings.refresh`)
            }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "patientSettings",
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.settings"),
    };
  },
  data() {
    return {
      valid: true,
      loader: null,
      loading: false,
      isEditBtn: false,
      patientName: null,
      patientAge: null,
      patientEmail: null,
      patientNotificationPerHourOnPhone: false,
      patientNotificationBlog: false,
      patientNotificationPerHoursOnEmail: false,
      patientNotificationOnEmailPerDynamicTime: "",
      patientNotificationOnEmailChecked: false,
      patientNameRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientNameRules"
          ),
      ],
      patientAgeRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientAgeRules1"
          ),
        (v) =>
          +v > 16 ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientAgeRules2"
          ),
      ],
      patientEmailRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientEmailRules1"
          ),
        (v) =>
          /.+@.+/.test(v) ||
          this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepOne.patientEmailRules2"
          ),
      ],
      items: [
        {
          id: 1,
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.notificationLabel.1"
          ),
        },
        {
          id: 2,
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.notificationLabel.2"
          ),
        },
        {
          id: 5,
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.notificationLabel.5"
          ),
        },
        {
          id: 12,
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.notificationLabel.12"
          ),
        },
        {
          id: 24,
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.notificationLabel.24"
          ),
        },
      ],
      userPhoneNumber: null,
      defaultCountry: "",
      countriesList: ["UA", "RU", "BY", "MD"],
      hasLoaderActive: false,
      hasErrorActive: false,
      userData: {},
    };
  },
  watch: {
    userData() {
      let countryCode = this.userData.countryCode;
      let nationalNumber = this.userData.nationalNumber;

      switch (countryCode) {
        case "UA":
          if (nationalNumber) {
            if (Array.from(nationalNumber).length > 9) {
              this.hasErrorActive = true;
              this.userData.isValid = false;
            } else {
              this.hasErrorActive = false;
            }
          }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    translations() {
      return {
        countrySelectorLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorLabel"
        ),
        countrySelectorError: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorError"
        ),
        phoneNumberLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.phoneNumberLabel"
        ),
        example: this.$vuetify.lang.t("$vuetify.view.signIn.example"),
      };
    },
  },
  beforeCreate() {
    this.$store.dispatch("user/getProfile", {});
  },
  beforeMount() {
    this.patientName = this.user.name;

    this.patientAge = this.user.age;

    this.patientEmail = this.user.email;

    this.userPhoneNumber = this.user.phone;

    this.defaultCountry = this.user.phone_country_code;

    this.patientNotificationPerHourOnPhone =
      this.user.setting_sms_before_session;

    this.patientNotificationBlog = this.user.setting_subscribed_mailings;

    this.patientNotificationOnEmailChecked =
      this.user.setting_email_before_session;

    this.patientNotificationOnEmailPerDynamicTime =
      this.user.setting_email_before_session_hours_value;
  },
  methods: {
    async updateUserSetting() {
      let payload = {
        phone: this.userData.formattedNumber,
        phone_country_code: this.userData.countryCode,
        email: this.patientEmail,
        questionnaire: {
          setting_subscribed_mailings: +this.patientNotificationBlog,
          setting_sms_before_session: +this.patientNotificationPerHourOnPhone,
          setting_email_before_session_hours:
            this.patientNotificationOnEmailPerDynamicTime,
          setting_email_before_session: +this.patientNotificationOnEmailChecked,
          name: this.patientName,
          age: this.patientAge,
        },
      };
      if (this.userData.isValid) {
        await this.$store.dispatch(
          "user/updateProfile",
          JSON.stringify(payload)
        );
      }
    },
    updatedData(data) {
      this.userData = data;
    },
    validate() {
      !this.userData.isValid ? this.$refs.form.validate() : this.hasErrorActive;
      this.valid ? (this.isEditBtn = !this.isEditBtn) : this.valid;

      this.updateUserSetting();

      this.hasErrorActive = !this.userData.isValid;
    },
  },
};
</script>

<style scoped></style>
