var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"disabled":!_vm.isEditBtn},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('p',{staticClass:"font-weight-medium title ma-0"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.title"))+" ")]),_c('div',{staticClass:"d-flex align-baseline"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":_vm.patientNameRules,"label":_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.name"),"value":"","outlined":""},model:{value:(_vm.patientName),callback:function ($$v) {_vm.patientName=$$v},expression:"patientName"}})],1)],1),_c('div',{staticClass:"d-flex align-baseline"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":_vm.patientAgeRules,"label":_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.age"),"outlined":""},model:{value:(_vm.patientAge),callback:function ($$v) {_vm.patientAge=$$v},expression:"patientAge"}})],1)],1),_c('p',{staticClass:"font-weight-medium title ma-0"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.contacts"))+" ")]),_c('div',{staticClass:"d-flex align-baseline"},[_c('v-col',{attrs:{"md":"8"}},[_c('vue-phone-number-input',{attrs:{"disabled":!_vm.isEditBtn,"loader":_vm.hasLoaderActive,"error":_vm.hasErrorActive,"default-country-code":_vm.defaultCountry,"preferred-countries":_vm.countriesList,"show-code-on-list":"","required":"","translations":_vm.translations},on:{"update":_vm.updatedData},model:{value:(_vm.userPhoneNumber),callback:function ($$v) {_vm.userPhoneNumber=$$v},expression:"userPhoneNumber"}}),_c('v-checkbox',{attrs:{"label":_vm.$vuetify.lang.t(
                "$vuetify.main.patientDashboard.settings.smsNotification"
              )},model:{value:(_vm.patientNotificationPerHourOnPhone),callback:function ($$v) {_vm.patientNotificationPerHourOnPhone=$$v},expression:"patientNotificationPerHourOnPhone"}})],1)],1),_c('div',{staticClass:"d-flex align-baseline"},[_c('v-col',{attrs:{"md":"8"}},[_c('v-text-field',{attrs:{"rules":_vm.patientEmailRules,"label":"Email","placeholder":this.$store.getters['user/getUser'].email,"required":"","outlined":"","hint":_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.hint"),"persistent-hint":""},model:{value:(_vm.patientEmail),callback:function ($$v) {_vm.patientEmail=$$v},expression:"patientEmail"}}),_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"label":_vm.$vuetify.lang.t(
                "$vuetify.main.patientDashboard.settings.blogLabel"
              )},model:{value:(_vm.patientNotificationBlog),callback:function ($$v) {_vm.patientNotificationBlog=$$v},expression:"patientNotificationBlog"}}),_c('v-row',{staticClass:"d-flex align-center pl-3"},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"label":_vm.$vuetify.lang.t(
                  "$vuetify.main.patientDashboard.settings.notificationLabel"
                )},model:{value:(_vm.patientNotificationOnEmailChecked),callback:function ($$v) {_vm.patientNotificationOnEmailChecked=$$v},expression:"patientNotificationOnEmailChecked"}}),_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"disabled":!_vm.patientNotificationOnEmailChecked,"items":_vm.items,"item-value":"id","item-text":"title","label":_vm.$vuetify.lang.t(
                    "$vuetify.main.patientDashboard.settings.timeLabel"
                  ),"outlined":""},model:{value:(_vm.patientNotificationOnEmailPerDynamicTime),callback:function ($$v) {_vm.patientNotificationOnEmailPerDynamicTime=$$v},expression:"patientNotificationOnEmailPerDynamicTime"}})],1),_c('p',{staticClass:"align-self-center text--secondary",class:{ 'text--disabled': !_vm.isEditBtn }},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.patientDashboard.settings.beforeSession" ))+" ")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[(_vm.isEditBtn)?_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":"","color":"success"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.save"))+" ")]):_vm._e(),(!_vm.isEditBtn)?_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":"","color":"warning"},on:{"click":function($event){_vm.isEditBtn = !_vm.isEditBtn}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.main.patientDashboard.settings.refresh"))+" ")]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }